var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 text-lg-h5 text-xl-h4 pb-8 font-weight-bold"
  }, [_vm._v(" Create Hiring Account ")]), _c('v-card-text', [_c('div', {
    staticClass: "google__signup"
  }, [_c('v-btn', {
    staticClass: "py-5 text-none",
    staticStyle: {
      "border": "1px solid #c4c4c4",
      "background": "white"
    },
    attrs: {
      "width": "100%",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.validateGoogleSignUp.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "pt-2 pr-3 text-subtitle-1",
    domProps: {
      "innerHTML": _vm._s(_vm.coloredGoogleIcon)
    }
  }), _vm._v(" Sign Up With Google ")])], 1), _c('div', {
    staticClass: "divider py-6"
  }, [_vm._v("OR")]), _c('v-form', {
    ref: "signUpRef",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.signUpForm,
      callback: function ($$v) {
        _vm.signUpForm = $$v;
      },
      expression: "signUpForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "background-color": "white",
      "placeholder": "Name",
      "rules": [function (v) {
        return !!v || 'Name is required';
      }]
    },
    model: {
      value: _vm.fullName,
      callback: function ($$v) {
        _vm.fullName = $$v;
      },
      expression: "fullName"
    }
  }), _c('v-text-field', {
    attrs: {
      "background-color": "white",
      "placeholder": "Work Email",
      "rules": _vm.emailRules
    },
    model: {
      value: _vm.workEmail,
      callback: function ($$v) {
        _vm.workEmail = $$v;
      },
      expression: "workEmail"
    }
  })], 1), _c('v-checkbox', {
    attrs: {
      "error-messages": _vm.TOSError,
      "rules": [function (v) {
        return !!v || 'You must agree to continue!';
      }]
    },
    on: {
      "change": _vm.validateTOS
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Agree to  "), _c('router-link', {
          staticClass: "danger--text",
          attrs: {
            "to": "/terms-of-service"
          }
        }, [_vm._v(" Terms and Conditions ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.agreeToTOS,
      callback: function ($$v) {
        _vm.agreeToTOS = $$v;
      },
      expression: "agreeToTOS"
    }
  }), _c('v-card-actions', {
    staticClass: "flex-column"
  }, [_c('v-btn', {
    staticClass: "rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6",
    attrs: {
      "width": "100%",
      "color": "#2EAC68",
      "disabled": !_vm.signUpForm,
      "loading": _vm.signingUp
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.validateSignUpForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Sign Up ")]), _c('div', {
    staticClass: "align-self-start mt-8"
  }, [_vm._v(" Already have an account? "), _c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Login.")])], 1), _vm.whitelabelConfig.enableCommunity ? _c('div', {
    staticClass: "align-self-start mt-8"
  }, [_vm._v(" Looking for community account? "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v("Sign up for community.")])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }